"use client";
import { Vehicle } from "@alba-cars/common-modules";
import Link from "next/link";
import { useRef, useState } from "react";
import type { Swiper as SwiperType } from "swiper";
import { Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import IconArrowDown from "@/components/Icons/IconArrowDown";
import CarsCard from "@/components/ui/CarsCard";
import "swiper/css";
import "swiper/css/navigation";

interface NewCarsProps {
  cars: Vehicle[];
}

const NewCarsSwiper = ({ cars }: NewCarsProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperType | null>(null);

  const handleNextSlide = () => {
    if (!swiperRef.current) return;

    if (activeIndex === cars.length - 1) {
      swiperRef.current.slideTo(0);
    } else {
      swiperRef.current.slideNext();
    }
  };

  if (!cars || cars.length === 0) {
    return (
      <div className="p-4 text-center">
        <p className="text-gray-500">No cars available at the moment.</p>
      </div>
    );
  }

  return (
    <div className="">
      <div className="mb-6 flex items-center justify-between px-2">
        <h3 className="urbanist text-xl font-semibold">Our Latest Cars</h3>
        <Link
          href="/buy-used-cars-uae?sort%5BcreatedAt%5D=desc"
          className="inter font-semibold text-primary transition-all duration-300 hover:text-primary-dark hover:underline"
        >
          See All
        </Link>
      </div>
      <div className="relative">
        <Swiper
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          modules={[Navigation, Virtual]}
          navigation={{
            nextEl: ".related-swiper-next",
            prevEl: ".related-swiper-prev",
          }}
          preventInteractionOnTransition={true}
          virtual={{
            addSlidesAfter: 1,
            addSlidesBefore: 1,
            enabled: true,
          }}
          slidesPerView={1}
          spaceBetween={16}
          direction="vertical"
          className="h-[450px]"
          autoplay
        >
          {cars.map((vehicle: Vehicle) => (
            <SwiperSlide key={vehicle.id}>
              <CarsCard car={vehicle} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex justify-end">
          <button
            onClick={handleNextSlide}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleNextSlide();
              }
            }}
            className="z-50 flex w-full items-center justify-center bg-white text-center text-white transition-all duration-300"
            aria-label="Next slide"
            tabIndex={0}
          >
            <span className="flex animate-bounce items-center gap-2 text-primary">
              <IconArrowDown fill="#ff3347" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewCarsSwiper;

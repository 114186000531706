import { FC } from "react";

interface IconPhoneProps {
  className?: string;
  fill?: string;
}

const IconPhone: FC<IconPhoneProps> = ({ fill }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7955 9.40683L10.416 9.78421C10.416 9.78421 9.51381 10.6812 7.0513 8.23273C4.58879 5.7842 5.49095 4.88717 5.49095 4.88717L5.72996 4.64951C6.31876 4.06406 6.37427 3.12412 5.86057 2.43793L4.80974 1.03426C4.17394 0.184956 2.94533 0.0727636 2.21657 0.797383L0.908572 2.09796C0.547222 2.45726 0.305074 2.92302 0.334438 3.4397C0.40956 4.76154 1.00763 7.60557 4.34483 10.9238C7.88382 14.4427 11.2044 14.5826 12.5623 14.456C12.9918 14.4159 13.3653 14.1972 13.6663 13.8979L14.8501 12.7208C15.6492 11.9263 15.4239 10.5641 14.4015 10.0083L12.8094 9.14287C12.1381 8.77793 11.3202 8.88513 10.7955 9.40683Z"
        fill={fill ?? "#292D34"}
      />
    </svg>
  );
};

export default IconPhone;

import PhoneLink from "../common/links/PhoneLink";
import IconPhone from "../Icons/IconPhone";

interface ContactLinkProps {
  phoneNumber: string;
  className?: string;
  iconFill?: string;
}

export const ContactLink = ({
  phoneNumber = "+97143772503",
  className,
  iconFill = "currentColor",
}: ContactLinkProps) => {
  return (
    <PhoneLink
      phoneNumber={phoneNumber}
      linkClassNames={`flex items-center font-medium hover:underline ${className}`}
      buttonClassNames={`flex items-center font-medium hover:underline ${className}`}
    >
      <IconPhone fill={iconFill} />
      <span className="ml-2">{phoneNumber}</span>
    </PhoneLink>
  );
};

"use client";

import { makeArray, VehicleFilter } from "@alba-cars/common-modules";
import { useRouter } from "next/navigation";
import { useCallback, useMemo, useReducer } from "react";

import { searchFiltersReducer } from "./searchFiltersReducer";
import { useFilterContext } from "@/app/buy-used-cars-uae/_components/context/FilterContext";
import FilterDropdownFullScreen from "@/app/buy-used-cars-uae/_components/FullScreenDialog";
import PriceFilter from "@/app/buy-used-cars-uae/_components/PriceFilter";
import { useVehicleFiltersData } from "@/app/buy-used-cars-uae/_hooks/useVehicleFilterData";
import FilterDropdown from "@/components/ui/FilterDropdown";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { MakeOption, ModelOption, OmittedVehicleGetDTO } from "@/lib/types";
import { getValueOrFirstOption } from "@/utils/utils";

const SearchCarsSidebar: React.FC = () => {
  const router = useRouter();
  const { queryOptions, resetQuery, encodeFilterToURL } = useFilterContext();
  const isSmallerDevice = useMediaQuery("(max-width: 768px)");

  const {
    makesOptions,
    modelsOptions,
    isMakesOptionsLoading,
    isModelsOptionsLoading,
    makesLookup,
    modelsLookup,
    setMakes: setSelectedMakes,
  } = useVehicleFiltersData();

  const [pendingFilters, dispatch] = useReducer<OmittedVehicleGetDTO, [any]>(searchFiltersReducer, queryOptions);

  const updatePendingFilters = useCallback(
    (payload?: Partial<VehicleFilter>) => {
      dispatch({ type: "update", payload });
    },
    [dispatch],
  );

  const handleFilterChange = useCallback(
    (payload?: Partial<VehicleFilter>, callback?: Function) => {
      updatePendingFilters(payload);
      callback?.();
    },
    [updatePendingFilters],
  );

  const selectedMakes = useMemo(() => {
    if (!pendingFilters.filter?.make?.length) return null;
    return makeArray(pendingFilters.filter.make)
      .map((makeId) => makesLookup.get(getValueOrFirstOption(makeId)))
      .filter(Boolean);
  }, [pendingFilters.filter?.make, makesLookup]);

  const selectedModels = useMemo(() => {
    if (!pendingFilters.filter?.model?.length) return null;
    return makeArray(pendingFilters.filter.model)
      .map((modelId) => modelsLookup.get(getValueOrFirstOption(modelId)))
      .filter(Boolean);
  }, [pendingFilters.filter?.model, modelsLookup]);

  const handleReset = useCallback(() => {
    dispatch({ type: "reset" });
    resetQuery();
    setSelectedMakes(undefined);
  }, [resetQuery, setSelectedMakes]);

  const applyFilters = useCallback(() => {
    const params = encodeFilterToURL(pendingFilters.filter ?? {}, pendingFilters.options?.sort);
    router.push(`/search?${params.toString()}`);
  }, [encodeFilterToURL, pendingFilters, router]);

  return (
    <aside aria-label="Search Cars">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          applyFilters();
        }}
        className="space-y-2"
      >
        <div className="rounded-2xl bg-white p-6">
          <div className="mb-6 flex items-center justify-between">
            <h3 className="urbanist text-xl font-semibold">Search Cars</h3>
            <button
              className="inter font-semibold text-primary transition-all duration-300 hover:text-primary-dark"
              type="button"
              onClick={handleReset}
            >
              Reset All
            </button>
          </div>
          <div className="space-y-4">
            {isSmallerDevice ? (
              <FilterDropdownFullScreen
                isSearchable={false}
                id="make-filter"
                options={makesOptions ?? []}
                isLoading={isMakesOptionsLoading}
                helperText="Make"
                onSelect={(options) =>
                  handleFilterChange({ make: options.map((option: MakeOption) => option.value) }, () => {
                    setSelectedMakes(options.map((option: MakeOption) => option.value));
                  })
                }
                onClear={() =>
                  handleFilterChange({ make: undefined, model: undefined }, () => {
                    setSelectedMakes(undefined);
                  })
                }
                value={selectedMakes as any}
                isMulti
              />
            ) : (
              <FilterDropdown
                id="make-filter"
                options={makesOptions ?? []}
                isLoading={isMakesOptionsLoading}
                helperText="Make"
                onSelect={(options) =>
                  handleFilterChange({ make: options.map((option: MakeOption) => option.value) }, () => {
                    setSelectedMakes(options.map((option: MakeOption) => option.value));
                  })
                }
                onClear={() =>
                  handleFilterChange({ make: undefined, model: undefined }, () => {
                    setSelectedMakes(undefined);
                  })
                }
                value={selectedMakes as any}
                withLabel
                isMulti
              />
            )}
            {isSmallerDevice ? (
              !!selectedMakes ? (
                <FilterDropdownFullScreen
                  isSearchable={false}
                  id="model-filter"
                  options={modelsOptions ?? []}
                  isLoading={isModelsOptionsLoading}
                  helperText="Model"
                  onSelect={(options) =>
                    handleFilterChange({ model: options.map((option: ModelOption) => option.value) })
                  }
                  onClear={() => handleFilterChange({ model: undefined })}
                  value={selectedModels as any}
                  customNoOptionsMessage={"Please select a make first "}
                  customOption={(option: unknown) => (
                    <div className="inter flex items-center gap-2">
                      <span className="text-base font-medium">{(option as ModelOption).make.name}</span>
                      <span className="text-base font-medium">-</span>
                      <span className="text-base font-medium">{(option as ModelOption).label}</span>
                    </div>
                  )}
                  isMulti
                />
              ) : null
            ) : (
              <FilterDropdown
                id="model-filter"
                options={modelsOptions ?? []}
                isLoading={isModelsOptionsLoading}
                helperText="Model"
                onSelect={(options) =>
                  handleFilterChange({ model: options.map((option: ModelOption) => option.value) })
                }
                onClear={() => handleFilterChange({ model: undefined })}
                value={selectedModels as any}
                customNoOptionsMessage={() => "Please select a make first "}
                withLabel
                isMulti
              />
            )}
            <PriceFilter
              onFromPriceChange={(fromPrice) => {
                const fromPriceValue = (fromPrice as unknown as { value: number; label: string })?.value;
                handleFilterChange({
                  price: { ...(pendingFilters.filter?.price ?? {}), min: fromPriceValue },
                });
              }}
              onFromPriceClear={() => {
                handleFilterChange({
                  price: { ...(pendingFilters.filter?.price ?? {}), min: undefined },
                });
              }}
              onToPriceChange={(toPrice) => {
                const toPriceValue = (toPrice as unknown as { value: number; label: string })?.value;
                handleFilterChange({
                  price: { ...(pendingFilters.filter?.price ?? {}), max: toPriceValue },
                });
              }}
              onToPriceClear={() => {
                handleFilterChange({
                  price: { ...(pendingFilters.filter?.price ?? {}), max: undefined },
                });
              }}
              onReset={() => handleFilterChange({ price: undefined })}
              fromValue={
                pendingFilters.filter?.price?.min
                  ? { value: pendingFilters.filter.price.min, label: pendingFilters.filter.price.min.toString() }
                  : null
              }
              toValue={
                pendingFilters.filter?.price?.max
                  ? { value: pendingFilters.filter.price.max, label: pendingFilters.filter.price.max.toString() }
                  : null
              }
            />
            <div className="mt-4 flex items-center justify-between gap-2">
              <button
                className="inter flex-1 rounded-lg bg-primary-light py-3 text-center text-sm font-semibold text-primary transition-all duration-300 hover:cursor-pointer hover:bg-primary hover:text-white"
                type="submit"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </aside>
  );
};

export default SearchCarsSidebar;

import { FC } from "react";

interface IconMapPinProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
}

const IconMapPin: FC<IconMapPinProps> = ({ width, height, fill, className }) => {
  return (
    <svg
      width={width ?? "16"}
      height={height ?? "19"}
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09488 15.6112C12.9802 15.1702 15.9986 11.8814 15.9986 7.88979C15.9986 3.59761 12.5086 0.118103 8.20341 0.118103C3.89824 0.118103 0.408203 3.59761 0.408203 7.88979C0.408203 11.8353 3.35715 15.094 7.17745 15.5948L8.13891 18.4705L9.09488 15.6112Z"
        fill="#FF3448"
      />
      <ellipse cx="8.20451" cy="7.88978" rx="4.8256" ry="4.81105" fill={fill ?? "#FFF3F4"} />
    </svg>
  );
};

export default IconMapPin;

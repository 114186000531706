import { VehicleFilter } from "@alba-cars/common-modules";
import { Reducer } from "react";

import { OmittedVehicleGetDTO } from "../../../lib/types";

type FilterAction =
  | { type: "update"; payload?: Partial<Omit<VehicleFilter, "validate">> }
  | { type: "reset"; payload?: Partial<Omit<VehicleFilter, "validate">> };

export const searchFiltersReducer: Reducer<OmittedVehicleGetDTO, FilterAction> = (
  state: OmittedVehicleGetDTO,
  action: FilterAction,
) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        filter: { ...state.filter, ...(action.payload ?? {}) },
      };
    case "reset":
      return {
        filter: new VehicleFilter(),
        options: {
          sort: undefined,
          page: 1,
        },
      } as OmittedVehicleGetDTO;
    default:
      return state;
  }
};

"use client";

import { useState } from "react";

import Map from "@/components/features/Map";
import Icon from "@/components/Icons/Icon";
import IconMapPin from "@/components/Icons/IconMapPin";
import IconMapPinHover from "@/components/Icons/IconMapPinHover";
import { ContactLink } from "@/components/navigation/ContactLink";
import { Button } from "@/components/ui/buttons/Button";
import { cn } from "@/lib/utils";
import { HeadingTag } from "@/utils/constants";

interface LocationMapCardProps {
  location: {
    title: string;
    address: {
      line1: string;
      line2: string;
    };
  };
  mapClassName?: string;
  showContactInfo?: boolean;
  TitleTag?: HeadingTag;
}

const LocationMapCard = ({
  location: { title, address },
  mapClassName = "",
  showContactInfo = false,
  TitleTag = "h2",
}: LocationMapCardProps) => {
  const [hoveredIconKey, setHoveredIconKey] = useState<string | null>(null);

  const handleGetDirections = () => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=ALBA+CARS+-+No.+1+Used+Car+Showroom+in+Dubai,+Al+Asayel+St+-+Al+Quoz+-+Al+Quoz+Industrial+Area+1+-+Dubai`,
    );
  };

  return (
    <div className="flex h-full flex-col rounded-2xl bg-white shadow-sm">
      {/* Header Section */}
      <div className="p-5">
        <TitleTag className="mb-2 font-urbanist text-xl font-semibold text-primary-foreground">{title}</TitleTag>
        <div className="sm:flex sm:items-center sm:justify-between">
          <p className="inter text-sm font-medium leading-relaxed text-gray-500">
            {address.line1}
            <br />
            {address.line2}
          </p>
          <Button
            onClick={handleGetDirections}
            className="mt-3 flex h-min items-center gap-2 whitespace-nowrap rounded-xl bg-[#FFF1F1] px-4 py-3 text-sm text-primary transition-all duration-200 hover:bg-primary hover:text-white sm:ml-4 sm:mt-0"
            onMouseEnter={() => setHoveredIconKey("get-directions")}
            onMouseLeave={() => setHoveredIconKey(null)}
          >
            <Icon icon={hoveredIconKey === "get-directions" ? <IconMapPinHover /> : <IconMapPin />} />
            <span className="inter text-sm font-semibold">Get directions</span>
          </Button>
        </div>

        {showContactInfo && (
          <div className="mx:mt-0 mt-4">
            <div className="inter text-sm font-medium leading-relaxed text-black">
              <div className="flex">
                <span className="inter text-sm font-medium leading-relaxed text-secondary-foreground">Phone:</span>
                <ContactLink
                  phoneNumber={"+97143772503"}
                  iconFill="transparent"
                  className="text-primary-forground font-medium"
                />
              </div>
              <div className="flex gap-2">
                <span className="inter text-sm font-medium leading-relaxed text-secondary-foreground">
                  Working Hours:
                </span>
                <span className="inter text-sm font-medium leading-relaxed">7 days : 10 AM - 10 AM</span>
            
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Map Section - Takes remaining space */}
      <div className={cn("relative flex-grow px-3 pb-3", mapClassName)}>
        <Map lat={23.4241} lng={53.8478} />
      </div>
    </div>
  );
};

export default LocationMapCard;

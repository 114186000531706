"use client";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React from "react";

interface MapProps {
  lat: number;
  lng: number;
}

const containerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "16px",
};

const showroom1 = {
  lat: 25.1401067,
  lng: 55.239651,
};

const center = {
  lat: 25.176987,
  lng: 55.296249,
};

const mapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
  zoomControl: true,
  styles: [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [{ color: "#565656" }],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ color: "#9cc0f9" }],
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [{ color: "#f5f5f5" }],
    },
  ],
};
const Map: React.FC<MapProps> = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "",
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={11} options={mapOptions}>
      <Marker
        position={showroom1}
        icon={{
          url: "/assets/images/custom-marker.svg",
          scaledSize: new google.maps.Size(40, 40),
        }}
      />
    </GoogleMap>
  ) : (
    <div className="flex h-full w-full items-center justify-center rounded-2xl bg-gray-100">
      <div className="text-center">
        <div className="mx-auto mb-2 h-6 w-6 animate-spin rounded-full border-2 border-primary border-t-transparent"></div>
      </div>
    </div>
  );
};

export default Map;
